import { useForm, Form, Input, InputNumber, Select, useSelect, Edit } from "@pankod/refine-antd";
import { useNavigation } from "@pankod/refine-core";
import { useParams } from "@pankod/refine-react-router-v6";
import React from "react";

export const CategoryEdit: React.FC = () => {
  const { push } = useNavigation();
  const { id } = useParams();
  const { formProps, saveButtonProps } = useForm({
    resource: "category-forum",
    redirect: false,
    id,
    onMutationSuccess: () => {
      push("/admin/chat/category");
    },
    metaData: {
      join: {
        field: "company",
      },
    },
  });
  const { selectProps } = useSelect({
    resource: "company",
    optionLabel: "name",
    optionValue: "id",
  });

  return (
    <Edit saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Form.Item
          className="w-100"
          label={"Name"}
          name="name"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={"Company"}
          name={["company", "id"]}
          rules={[
            {
              required: true,
            },
          ]}
          className="w-full"
        >
          <Select {...selectProps} placeholder="Company" />
        </Form.Item>
        <Form.Item className="w-100" label={"Order"} name="order">
          <InputNumber />
        </Form.Item>
      </Form>
    </Edit>
  );
};
