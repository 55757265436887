import { DeleteButton, EditButton, List, Table, useTable } from "@pankod/refine-antd";
import { css } from "@emotion/react";
import { useMe } from "context/MeContext";
import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faEyeSlash, faEye } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { useUpdate } from "@pankod/refine-core";
library.add(far, faEyeSlash, faEye);

export const ChallengeAdmin: React.FC = () => {
  const { company, isSuperAdmin } = useMe();
  const { mutate: updateChallenge } = useUpdate();
  const { tableProps } = useTable({
    resource: "challenge",
    permanentFilter: !isSuperAdmin
      ? [
          {
            field: "companies.id",
            operator: "eq",
            value: company?.id,
          },
        ]
      : [],
    metaData: {
      join: {
        field: "companies",
      },
    },
    initialSorter: [
      {
        field: "createdAt",
        order: "desc",
      },
    ],
  });
  const data = tableProps?.dataSource;
  console.log(data);
  useEffect(() => {
    const data = tableProps?.dataSource;
    if (data) {
      const initialHiddenChallenges: { [key: string]: boolean } = {};
      data?.forEach((challenge: any) => {
        initialHiddenChallenges[challenge.id] = !challenge.status;
      });
      setHiddenChallenges(initialHiddenChallenges);
    }
  }, [data]);

  // const { mutate: updateChallenge } = useUpdate();
  const [hiddenChallenges, setHiddenChallenges] = useState<{ [key: string]: boolean }>({});

  function toggleChallengeVisibility(id: string) {
    const isHidden = hiddenChallenges[id];
    setHiddenChallenges((prev) => ({ ...prev, [id]: !isHidden }));

    data?.forEach((item: any) => {
      if (item.id === id) {
        item.status = !isHidden;
      }
    });
    updateChallenge({
      resource: "challenge",
      id: id,
      values: {
        status: isHidden,
      },
      successNotification: false,
    });
  }


  const columns = [
    {
      title: "Challenge ID",
      dataIndex: "id",
      key: "id",
      width: "15%",
    },
    {
      title: "Challenge name",
      dataIndex: "name",
      key: "name",
      width: "30%",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      width: "55%",
    },
    {
      title: "Actions",
      render: (_: any, record: any) => {
        return (
          <div
            className="flex space-x-2"
            css={css`
              .eye-icon {
                background-color: white !important;
                border-radius: 50%;
                border: 1px solid #1f1f1f;
                width: 32px;
                height: 32px;
              }
            `}
          >
            <EditButton shape="circle" hideText recordItemId={record.id} />
            <DeleteButton shape="circle" hideText recordItemId={record.id} resourceNameOrRouteName="challenge" />
            <button onClick={() => toggleChallengeVisibility(record.id)} className="eye-icon">
              <FontAwesomeIcon icon={hiddenChallenges[record.id] ? faEyeSlash : faEye} />
            </button>
            {/* <Select>
              <Option value="true">Admin Only</Option>
              <Option value="false">Visible to All</Option>
            </Select> */}
          </div>
        );
      },
      width: "15%",
    },
  ];
  return (
    <List title={<h1 style={{fontSize: 35, marginTop:20, color:"rgb(4, 6, 10, 0.8)"}}>Challenges</h1>} canCreate>
      <Table
        {...tableProps}
        rowKey="id"
        columns={columns}
        pagination={{
          ...tableProps.pagination,
        }}
      />
    </List>
  );
};
