import { DeleteButton, EditButton, List, Table, useTable } from "@pankod/refine-antd";
import { useMe } from "context/MeContext";
import React from "react";

export const Activity: React.FC = () => {
  const { company, isSuperAdmin } = useMe();
  const { tableProps } = useTable({
    resource: "activity",
    initialSorter: [
      {
        field: "order",
        order: "asc",
      },
    ],
    permanentFilter: !isSuperAdmin
      ? [
          {
            field: "company_id",
            operator: "eq",
            value: company?.id,
          },
        ]
      : [],
  });
  const columns = [
    {
      title: "Order",
      dataIndex: "order",
      key: "order",
      width: "10%",
    },
    {
      title: "Activity name",
      dataIndex: "name",
      key: "name",
      width: "70%",
    },
    {
      title: "Dimension",
      dataIndex: "dimensionId",
      key: "dimension",
      width: "20%",
    },
    {
      title: "Actions",
      render: (_: any, record: any) => {
        return (
          <div className="flex space-x-2">
            <EditButton shape="circle" hideText recordItemId={record.id} />
            <DeleteButton shape="circle" hideText recordItemId={record.id} resourceNameOrRouteName="activity" />
          </div>
        );
      },
      width: "10%",
    },
  ];
  return (
    <List title={<h1 style={{fontSize: 35, marginTop:20, color:"rgb(4, 6, 10, 0.8)"}}>Activities</h1>} canCreate>
      <Table
        {...tableProps}
        rowKey="id"
        columns={columns}
        pagination={{
          ...tableProps.pagination,
        }}
      />
    </List>
  );
};
