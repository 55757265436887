import React, { useState } from "react";
import routerProvider from "@pankod/refine-react-router-v6";
import { RefineLayoutHeaderProps } from "@pankod/refine-ui-types";
import { Layout, Typography, Avatar, Space, Button, Dropdown } from "antd";
import { useList, useLogout, useResource } from "@pankod/refine-core";
// import { Dropdown } from "@pankod/refine-antd";
import {
  SettingOutlined,
  UserOutlined,
  LogoutOutlined,
  QuestionCircleOutlined,
  FileProtectOutlined,
  DashboardOutlined,
} from "@ant-design/icons";
import empty from "assets/img/empty_ava.png";
import { useCompany } from "context/CompanyContext";
import { Notifications } from "./notifications";
// css
import "./header.scoped.scss";
import { css } from "@emotion/react";
import tw from "twin.macro";
import { useMe } from "context/MeContext";
import { config } from "config";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import { NotificationType } from "types/notification";
import { uniqBy } from "lodash-es";
library.add(faBell);

const { Link } = routerProvider;
const { Text } = Typography;

export const Header: React.FC<RefineLayoutHeaderProps> = () => {
  const [currentPageNoti, setCurrentPageNoti] = useState(1);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenSetting, setIsOpenSetting] = useState(false);
  const [isChange, setIsChange] = useState(false);
  const { path: company_path, brand, homepage } = useCompany();
  const { me: user, isAdmin, isSuperAdmin } = useMe();
  const icon = (user?.isChangedIcon ? config.ASSET_URL + user?.icon : user?.icon) || empty;
  const { mutate: logout } = useLogout();
  const background_header = brand?.background_color;
  const logo = brand?.logo;
  const borderRadius = brand?.border_radius;
  const [messages, setMessages] = useState<any[]>([]);

  // const { data: dataTrophy } = useList({
  //   resource: "user-trophy",
  // });

  // const { data: dataBestChallenge } = useList({
  //   resource: "user-best-challenge",
  // });

  // const { data: dataChallengeParticipated } = useList({
  //   resource: "challenge-participated",
  // });

  // const { data: dataChallengeParticipants } = useList({
  //   resource: "challenge-participants",
  // });

  // const { data: dataChallengeJoined } = useList({
  //   resource: "challenge-joined",
  // });

  // const { data: dataChallengeTotalParticipants } = useList({
  //   resource: "challenge-total-participants",
  // });

  // console.log({
  //   dataTrophy,
  //   dataChallengeParticipated,
  //   dataBestChallenge,
  //   dataChallengeParticipants,
  //   dataChallengeJoined,
  //   dataChallengeTotalParticipants,
  // });
  const { isFetching, refetch: refetchTotalNotification } = useList({
    resource: "notification",
    config: {
      filters: [
        {
          field: "type",
          operator: "nin",
          value: [NotificationType.Message, NotificationType.MessageReport, NotificationType.PostReport],
        },
        {
          field: "user_id",
          operator: "eq",
          value: user?.id,
        },
      ],
      pagination: {
        pageSize: 5,
        current: currentPageNoti,
      },
      sort: [
        {
          field: "createdAt",
          order: "desc",
        },
      ],
    },
    queryOptions: {
      onSuccess: (data) => {
        setMessages((prev) => {
          const _arr = uniqBy([...prev, ...(data.data || [])], "id");
          return _arr;
        });
      },
    },
  });

  const { data: dataNotificationSeen, refetch: refetchTotalUnseenNotification } = useList({
    resource: "notification",
    config: {
      filters: [
        {
          field: "type",
          operator: "nin",
          value: [NotificationType.Message, NotificationType.MessageReport, NotificationType.PostReport],
        },
        {
          field: "user_id",
          operator: "eq",
          value: user?.id,
        },
        {
          field: "seenAt",
          operator: "null",
          value: true,
        },
      ],
    },
  });
  const totalNotificationsUnseen = dataNotificationSeen?.total || 0;

  function Menu() {
    setIsChange((prevState) => !prevState);
    setIsOpen(!isOpen);
  }
  const profile = (
    <div
      className={`flex flex-col bg-white rounded-md acc-setting acc-setting-responsive`}
      css={css`
        span {
          ${tw` py-2 px-2 w-full`}
          color : ${background_header};
          display: inline;
        }
        .icon {
          margin: 20px 10px 0 0;
        }
        padding: 2rem;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
      `}
    >
      <span className="my-acc">
        <Link to="/myaccount">
          <button
            className="border-gradient border-gradient-green"
            css={css`
              border-color: ${background_header} !important;
              .username {
                color: ${background_header} !important;
              }
            `}
          >
            <Text ellipsis className="username">
              {user?.username}
            </Text>
          </button>
        </Link>
      </span>
      {/* <div className="hr-main">123</div> */}
      {(isAdmin || isSuperAdmin) && (
        <>
          <Link to="/admin/core-management/dashboard" className="item admin-mode lg-ava">
            <span>
              <UserOutlined className="icon" />
              <Text>Admin Mode</Text>
            </span>
          </Link>
          <div className="hr lg-ava"></div>
        </>
      )}
      <Link to="/user-dashboard" className="item">
        <span>
          <DashboardOutlined className="icon" />
          <Text>Dashboard</Text>
        </span>
      </Link>
      <div className="hr"></div>
      <Link to="/myaccount" className="item">
        <span>
          <SettingOutlined className="icon" />
          <Text>Settings</Text>
        </span>
      </Link>
      <div className="hr"></div>
      <Link to="/privacy" className="item">
        <span>
          <FileProtectOutlined className="icon" />
          <Text>Terms & privacy</Text>
        </span>
      </Link>
      <div className="hr"></div>
      <a
        href="https://monumenthealth.net/goodlife-tutorials/"
        className="item"
        target="_blank"
        rel="noopener noreferrer"
      >
        <span>
          <QuestionCircleOutlined className="icon" />
          <Text>Help & support</Text>
        </span>
      </a>
      <div className="hr"></div>
      {/* <span><MessageOutlined className="icon"/><Text>Give feedback</Text></span> */}
      <span
        className="item"
        onClick={() => {
          logout();
        }}
      >
        <LogoutOutlined className="logout" />
        <Text strong className="shadow-none border-none cursor-pointer">
          Log out
        </Text>
      </span>
    </div>
  );

  const { resourceName, resource } = useResource();

  const arrSecondary = [
    "contact",
    "resource",
    "privacy",
    "terms",
    "hra",
    "myaccount",
    "activity",
    "HRAresults",
    "forums",
    "topics",
    "posts",
    "new_post",
    "challenges",
    "user-dashboard",
  ];
  const shouldShowSecondaryHeader = arrSecondary.some(
    (item) => resource?.options?.route?.includes(item) || resourceName.includes(item),
  );

  return (
    <Layout.Header
      className={`j-between ${shouldShowSecondaryHeader ? "p-0" : ""}`}
      style={{
        alignItems: "center",
        margin: "0",
        zIndex: "10",
        width: "100%",
        height: "auto",
        backgroundColor: "transparent",
        padding: "0",
      }}
      css={css`
        .header-bg { 
          background-color: ${background_header};
          li {
            color: white !important;
          }
          .logologo img {
            ${company_path === "htop" || company_path === "marshall" ? "filter: brightness(0) invert(1);" : ""}
            ${
              company_path === "monument-health"
                ? "filter: brightness(4) grayscale(100%) opacity(0.8); mix-blend-mode: screen;"
                : ""
            }
            @media (max-width: 577px) {
              filter: none !important;
            }
          }
        }
        li {
          color: ${brand?.navbar_color};
          font-size: ${brand?.navbar_fontsize}px !important;
        }
      }
      `}
    >
      <div
        className={`w-full ${shouldShowSecondaryHeader ? "header-bg" : "header-bg1"}`}
        css={css`
          .blur-background {
            background-color: rgba(255, 255, 255, 0.5) !important;
            border-radius: 30px;
            ${company_path === "htop" || company_path === "d51"
              ? ".logo {width: 140px !important; margin-left: 10px; margin-top: 5px; align-item:center;};"
              : ""}
            ${company_path === "d51" ? ".logo {margin-top: 8px;};" : ""}
            ${company_path === "grand-junction-housing-authority"
              ? ".logo {width: 250px !important;};"
              : ".logo {width: 170px;};"}
          }
        `}
      >
        <div
          className={`j-between menu ${
            shouldShowSecondaryHeader ? "secondary nav-menu" : homepage?.nav_blur ? "blur-background" : ""
          } container`}
        >
          <div className="d51logo">
            <Link to={`${company_path}`} className="logologo">
              <img
                src={logo}
                alt="logo"
                className="logo"
                css={css`
                  ${borderRadius === true ? "border-radius: 10px;" : ""};
                  ${company_path === "grand-junction-housing-authority" ? "width: 250px !important;" : "width: 170px;"};
                `}
              />
            </Link>
          </div>
          <div className="navbar-container stroke">
            <ul
              className={`navbar j-between menumenu responsive-navbar ${isOpen ? "open" : ""} ${
                isOpenSetting ? "open-setting-padding" : "open-setting"
              }`}
              css={css`
                @media (max-width: 577px) {
                  background-color: ${brand?.color_primary} !important;
                }
                @media (max-width: 700px) {
                  li {
                    font-size: 12px !important;
                  }
                }
                @media (min-width: 600px) and (max-width: 740px) {
                  margin-right: 200px !important;
                  width: 50% !important;
                  li {
                    font-size: 10px !important;
                  }
                }
              `}
            >
              <Link to={`${company_path}`} onClick={Menu}>
                <li>HOME</li>
              </Link>
              <Link to={`${company_path}/challenge`} onClick={Menu}>
                <li>CHALLENGES</li>
              </Link>
              <Link to={`${company_path}/activity`} onClick={Menu}>
                <li>ACTIVITIES</li>
              </Link>
              <Link to={`${company_path}/hraresults`} onClick={Menu} className="hra-mobile">
                <li>HRA</li>
              </Link>
              <div className="dropdown hra-desktop">
                <div onClick={Menu} className="dropdown">
                  <li className="hra-child">HRA</li>
                </div>
                <div
                  className="dropdown-content desktop"
                  css={css`
                    .dropdown-item {
                      color: ${brand?.color_primary};
                    }
                  `}
                >
                  <Link to={`${company_path}/hra`} className="dropdown-item">
                    TAKE HRA
                  </Link>
                  <Link to={`${company_path}/hraresults`} className="dropdown-item">
                    YOUR HEALTH RISK REPORT
                  </Link>
                </div>
              </div>

              <Link to={`${company_path}/forums`} onClick={Menu}>
                <li>CHAT</li>
              </Link>
              <Link to={`${company_path}/resources`} onClick={Menu}>
                <li>RESOURCES</li>
              </Link>
              <Link to={`${company_path}/contact`} onClick={Menu}>
                <li>CONTACT</li>
              </Link>
              {/* <Dropdown className="dropdown dd-rs-sm" overlay={profile} placement="bottomRight">
                <Space>
                  <li className="setting-privacy" onClick={() => setIsOpenSetting(!isOpenSetting)}>
                    SETTINGS & PRIVACY
                  </li>
                </Space>
              </Dropdown> */}
            </ul>
          </div>
          <div
            className={`menu-rs ${isChange ? "menu-change" : ""}`}
            onClick={Menu}
            css={css`
              .change .bar1 {
                background-color: ${brand?.color_primary};
              }
              .change .bar3 {
                background-color: ${brand?.color_primary};
              }
              .menu-change {
                color: ${brand?.color_primary} !important;
              }
              right: 95px !important;
            `}
          >
            <div className={`bars ${isChange ? "change" : ""}`}>
              <div className="bar1"></div>
              <div className="bar2"></div>
              <div className="bar3"></div>
            </div>
            MENU
          </div>

          <div className="j-between noti-group">
            {totalNotificationsUnseen > 0 && (
              <div className="w-[20px] h-[20px] absolute top-[5px] z-10 text-[11px] rounded-full bg-red-500 flex items-center justify-center number-noti">
                {totalNotificationsUnseen}
              </div>
            )}

            <Dropdown
              className="dropdown dd-rs-md noti-group-dropdown"
              overlay={
                <Notifications
                  data={messages}
                  refetchTotalNotification={refetchTotalNotification}
                  setCurrentPageNoti={setCurrentPageNoti}
                  refetchTotalUnseenNotification={refetchTotalUnseenNotification}
                  isLoading={isFetching}
                />
              }
              placement="bottomRight"
            >
              <div className="bell-icon-group">
                <FontAwesomeIcon icon={faBell} className="bell-icon" />
              </div>
            </Dropdown>

            <Dropdown className="dropdown dd-rs-md" overlay={profile} placement="bottomRight">
              <Space>
                <Avatar size="large" src={icon} alt={user?.firstname + " " + user?.lastname} className="lg-ava" />
                <Avatar src={icon} alt={user?.firstname + " " + user?.lastname} className="sm-ava" />
              </Space>
            </Dropdown>
          </div>
        </div>
      </div>
    </Layout.Header>
  );
};
