import { Avatar, DeleteButton, EditButton, List, Table, useTable, Space } from "@pankod/refine-antd";
import { Button, Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { useMe } from "context/MeContext";
import dayjs from "dayjs";
import React from "react";
import { HttpError, CrudFilter } from "@pankod/refine-core";

interface DataType {
  id: number;
  name: string;
  email: string;
  username: string;
  employeeCode: string;
  date: string;
  gender: string;
  firstname: string;
}

export const Admin: React.FC = () => {
  const { isAdmin, isSuperAdmin, company } = useMe();
  const [trigger, setTrigger] = React.useState(0);
  const { tableProps, setFilters } = useTable<any, HttpError, any>({
    resource: "users",
    initialSorter: [
      {
        field: "createdAt", 
        order: "desc",
      },
    ],
    permanentFilter: !isSuperAdmin
      ? [
          {
            field: "companyId",
            operator: "eq",
            value: company?.id,
          },
        ]
      : [],
  });

  const resetAllFilters = () => {
    setFilters([], "replace");
    setTrigger((n) => n + 1);
  };
  const getColumnSearchProps = (dataIndex: keyof DataType) => ({
    filterDropdown: (props: any) => {
      const { setSelectedKeys, selectedKeys, confirm, clearFilters } = props;
      return (
        <div style={{ padding: 8 }}>
          <Input
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0] as string}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => confirm()}
            style={{ marginBottom: 8, display: "block" }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => {
                confirm();
              }}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Search
            </Button>
            <Button
              onClick={() => {
                clearFilters?.();
                setSelectedKeys([]);
                confirm();
              }}
              size="small"
              style={{ width: 90 }}
            >
              Reset
            </Button>
          </Space>
        </div>
      );
    },
    filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />,
    onFilter: (value: string | number | boolean | React.Key, record: DataType) => {
      const text = record[dataIndex];
      return text
        ? text
            .toString()
            .toLowerCase()
            .includes((value as string).toLowerCase())
        : false;
    },
    render: (text: string) => text,
  });

  const columns = [
    {
      title: "Avatar",
      dataIndex: "icon",
      key: "icon",
      render: (val: string) => <Avatar src={val} />,
      width: "5%",
    },
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
      sorter: true,
      ...getColumnSearchProps("username"),
    },
    {
      title: "Name",
      dataIndex: "firstname",
      key: "firstname",
      sorter: true,
      ...getColumnSearchProps("firstname"),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      sorter: true,
      ...getColumnSearchProps("email"),
    },
    {
      title: "EC",
      dataIndex: "employeeCode",
      key: "employeeCode",
      sorter: true,
      ...getColumnSearchProps("employeeCode"),
    },
    {
      title: "DOB",
      dataIndex: "dateOfBirth",
      key: "dateOfBirth",
      render: (val: string) => <>{val ? dayjs(val).format("YYYY-MM-DD") : "-"}</>,
    },
    {
      title: "Gender",
      dataIndex: "gender",
      key: "gender",
    },
    {
      title: "Updated at",
      dataIndex: "updatedAt",
      key: "updatedAt",
      render: (val: string) => <>{dayjs(val).format("YYYY-MM-DD")}</>,
    },
    {
      title: "Actions",
      render: (_: any, record: any) => (
        <div className="flex space-x-2">
          <EditButton shape="circle" hideText recordItemId={record.id} disabled={!(isAdmin || isSuperAdmin)} />
          <DeleteButton
            disabled={!(isAdmin || isSuperAdmin)}
            shape="circle"
            hideText
            recordItemId={record.id}
            resourceNameOrRouteName="users"
          />
        </div>
      ),
    },
  ];

  return (
    <List title={<h1 style={{fontSize: 35, marginTop:20, color:"rgb(4, 6, 10, 0.8)"}}>Users</h1>} canCreate>
      <Button onClick={resetAllFilters} style={{ marginBottom: 16 }}>
        Reset All Filters
      </Button>
      <Table
        {...tableProps}
        rowKey="id"
        key={trigger}
        columns={columns}
        onChange={(paging, filters, sorter, extra) => {
          const filterCrud: CrudFilter[] = [];
          Object.keys(filters)
            .filter((key) => !!filters[key])
            ?.forEach((key) => {
              filterCrud.push({
                field: key,
                operator: "contains",
                value: filters[key],
              });
            });
          setFilters(filterCrud, "replace");
          tableProps.onChange?.(paging, {}, sorter, extra);
        }}
      />
    </List>
  );
};
