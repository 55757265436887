import { Table, Pagination, } from "@pankod/refine-antd";
import { CrudFilters, useList } from "@pankod/refine-core";
import { useMe } from "context/MeContext";
import { IUser } from "types/user";
import { IUserActivity } from "types/user-activity";
import "./index.scoped.scss";
import { ITrophy } from "types/trophy";
import React, { useState, useEffect } from "react";
import { message } from "antd";
import championPhoto from "./../../assets/img/647.jpg";
import { css } from "@emotion/react";
import { useCompany } from "context/CompanyContext";
import { generateURL } from "utils/generateURL";
import styled from "styled-components";

export const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

interface RewardBoardProps {
  dataUsersTotal?: IUser[];
  count: number;
}


export const RewardBoard: React.FC<RewardBoardProps> = ({ count }) => {
  const { me: user, company } = useMe();
  const { leaderboard, id, path:company_path, hra_summary } = useCompany();
  const [messageApi, contextHolder] = message.useMessage();
  const [show1, setShow1] = useState(true);
  const [show2, setShow2] = useState(false);
  const trophy = user?.trophy || 0;
  const now = new Date();
  const year = now.getFullYear();
  const _month = now.getMonth() + 1;
  const filterCreatedAt: CrudFilters = [
    {
      field: "createdAt",
      operator: "gte",
      value: `${now.getFullYear()}-${now.getMonth() + 1}-01`,
    },
    {
      field: "createdAt",
      operator: "lte",
      value: `${now.getFullYear()}-${now.getMonth() + 1}-${new Date(year, _month, 0).getDate()}`,
    },
  ];
  const generalFilters: CrudFilters = [
    {
      field: "user.companyId",
      operator: "eq",
      value: id,
    },
  ];
  // total users activity in current month
  const [page1, setPage1] = useState(1);
  const [totalItems1, setTotalItems1] = useState(0);
  const { data: dataTotalUserActivity } = useList<IUserActivity>({
    resource: "user-activity",
    config: {
      filters: user?.hideMeDashboard
        ? [
            ...filterCreatedAt,
            ...generalFilters,
            {
              field: "userId",
              operator: "ne",
              value: user?.id,
            },
          ]
        : [...filterCreatedAt, ...generalFilters],
      sort: [
        {
          field: "point",
          order: "desc",
        },
        {
          field: "point", 
          order: "desc",
        },
      ],
      pagination: {
        pageSize: 10,
        current: page1,
      },
    },
    queryOptions: {
      enabled: Boolean(user?.id),
    },
  });
  const TotalUsersInMonth = dataTotalUserActivity?.data;
  useEffect(() => {
    if (dataTotalUserActivity?.data) {
      setTotalItems1(dataTotalUserActivity.total || 0);
    }
  }, [dataTotalUserActivity]);
  
  const handlePageChange1 = (page1: number) => {
    setPage1(page1);
  };

  // my activity in current month
  const { data: dataUserActivity } = useList<IUserActivity>({
    resource: "user-activity",
    config: {
      filters: [
        {
          field: "userId",
          operator: "eq",
          value: user?.id,
        },
      ],
      sort: [
        {
          field: "createdAt",
          order: "desc",
        },
      ],
    },
    queryOptions: {
      enabled: Boolean(user?.id),
    },
  });

  const data = dataUserActivity?.data;
  const userActitvityData = data?.[0];
  const month = monthNames[new Date().getMonth()];

  //trophy
  const { data: dataTrophyRaw } = useList<ITrophy>({
    resource: "trophy",
    config: {
      pagination: {
        pageSize: 1000,
      },
      sort: [
        {
          field: "point",
          order: "asc",
        },
      ],
    },
  });
  const dataTrophy = dataTrophyRaw?.data;
  const myTrophy = dataTrophy?.filter((item) => item.point <= trophy).at(-1);
  React.useEffect(() => {
    if (!myTrophy || !count) return;
    const message = setTimeout(() => {
      messageApi.open({
        content: (
          <div className="champion-noti">
            <img src={championPhoto} alt="" className="image-champion" />
            <h4>GREAT WORK !</h4>
            <h5>You've completed</h5>
            <h6>
              Level {myTrophy ? myTrophy.rank : 0}: <span>{myTrophy && myTrophy.name} </span>
            </h6>
            <p>
              Congratulations on slaying that goal like a wellness warrior! Please check the Reward Board below to see
              more detail about your achievement.
            </p>
          </div>
        ),
        className: "custom-class",
        duration: 3.5,
      });
    }, 3000);
    return () => clearTimeout(message);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messageApi, myTrophy]);


    //All-time Leaderboard
    const [page, setPage] = useState(1); 
    const [totalItems, setTotalItems] = useState<number>(0);
  
  const { data: dataUsers, refetch: refetchUsers, isLoading, isError } = useList<IUser>({
    resource: "users",
    config: {
      sort: [
        {
          field: "activityPoint",
          order: "desc",
        },
      ],
      pagination: {
        pageSize:10, 
        current: page, 
      },
      filters: [
        {
          field: "companyId",
          operator: "eq",
          value: company?.id
        },
        {
          field: "hideMeDashboard",
          operator: "eq",
          value: false,
        },
      ]
    },
  });
  const dataUsersTotal = dataUsers?.data;
  console.log(dataUsersTotal);
  useEffect(() => {
    if (dataUsers?.data) {
      setTotalItems(dataUsers.total || 0);
    }
  }, [dataUsers]);
  
  const handlePageChange = (page: number) => {
    setPage(page);
  };
  const StyledTable = styled(Table)`
  .ant-table-thead > tr > th {
    color: ${hra_summary?.main_hra_color} !important;
    background: #f5f5f5 !important; 
    border-radius: 0 !important;
  }
`;

  return (
    <div className="chart-box" css={css`
      .outer-title {
        background-color: ${hra_summary?.main_hra_color};
      }
    `}>
      {contextHolder}
      <div className="reward-board">
        <div className="reward-top j-between">
          <div className="top-left">
            <div
              className="reward-title large"
              css={css`
                color: ${leaderboard?.level_color};
              `}
            >
              Level {myTrophy ? myTrophy.rank : 0}:{" "}
              <span
                css={css`
                  color: ${leaderboard?.other_texts_color};
                `}
              >
                {myTrophy && myTrophy.name}
              </span>
            </div>
            <div
              className="reward-title large"
              css={css`
                color: ${leaderboard?.level_color};
              `}
            >
              Total Trophies:{" "}
              <span
                css={css`
                  color: ${leaderboard?.other_texts_color};
                `}
              >
                {trophy}
              </span>
            </div>
            <div className="plan-name green">
              Points in {month}: {userActitvityData?.point || 0}
            </div>
            <div className="plan-name green">Lifetime points: {user?.activityPoint || 0}</div>
          </div>
          <div className="top-right j-between" css={css`
            img {
              border-radius: 10px;
            }
            ${company_path === "grand-junction-housing-authority" ? "width: 40% !important;" : ""}
            `}>
            {/* <img src={leaderboard?.image_url} alt="trophy" /> */}
            {leaderboard?.image_url ? <img src={generateURL(leaderboard?.image_url)} alt="img" /> : null}
          </div>
        </div>
      </div>
      <div
        className="j-between reward-title-group"
        css={css`
          color: ${leaderboard?.level_color};
          .show {
            color: white;
            background-color: ${leaderboard?.level_color};
          }
        `}
      >
        <div
          className={`reward-title reward-responsive ${show1 ? "show" : ""}`}
          onClick={() => {
            setShow1(true);
            setShow2(false);
          }}
        >
          {month} - Current Leaderboard
        </div>
        <div
          className={`reward-title reward-responsive ${show2 ? "show" : ""}`}
          onClick={() => {
            setShow1(false);
            setShow2(true);
          }}
        >
          All-time Leaderboard
        </div>
      </div>
      <div className="reward-bottom j-between">
        <div className={`bottom-left ${show1 ? "show-class" : "hide-class"} outer-box`}>
          <div className="reward-title reward-title-hide outer-title">{month} - Current Leaderboard</div>
          {/* <Table rowKey={"id"} dataSource={dataTotalUserActivity?.data} columns={columns} /> */}
          <div className="mytable">
            <StyledTable
              rowKey={"id"}
              dataSource={TotalUsersInMonth}
              columns={columns}
              loading={isLoading}
              pagination={false}

            />

            {totalItems1 > 0 && (
              <Pagination
                current={page1}
                pageSize={10} 
                total={totalItems1}
                onChange={handlePageChange1}
                showSizeChanger={false}  
                style={{ float: "right", marginTop: "16px" }}
              />
            )}
          </div>
        </div>
        <div className={`bottom-right ${show2 ? "show-class" : "hide-class"} outer-box`}>
          <div className="reward-title reward-title-hide outer-title">All-time Leaderboard</div>
          {/* <Table rowKey={"id"} dataSource={dataUsersTotal} columns={columns_all_time} /> */}
          <div className="mytable">
            <StyledTable
              rowKey={"id"}
              dataSource={dataUsersTotal}
              columns={columns_all_time}
              loading={isLoading}
              pagination={false}
            />
            {totalItems > 0 && (
              <Pagination
                current={page}
                pageSize={10} 
                total={totalItems}
                onChange={handlePageChange}
                showSizeChanger={false}  
                style={{ float: "right", marginTop: "16px" }}
              />
            )} 
          </div>
        </div>
      </div>
    </div>
  );
};

const columns = [
  {
    title: "Username",
    dataIndex: ["user", "username"],
    key: "username",
  },
  {
    title: "Points",
    dataIndex: "point",
    key: "point",
  },
];

const columns_all_time = [
  {
    title: "Username",
    dataIndex: "username",
    key: "username",
  },
  {
    title: "Points",
    dataIndex: "activityPoint",
    key: "activityPoint",
  },
];
