import React from "react"
import { Card, Space, Button } from 'antd';
import { ArrowRightOutlined  } from '@ant-design/icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faRightLong,faArrowRightLong } from "@fortawesome/free-solid-svg-icons";
import "./user-dashboard.scoped.scss";
import umbrella from "../../assets/img/heart.png";
import gjhaUD from "../../assets/img/ud-gjha.png";
import mhUD from "../../assets/img/ud-mh.png";
import d51UD from "../../assets/img/ud-d51.png";
import mmUD from "../../assets/img/ud-mm.png";
import htUD from "../../assets/img/ud-ht.png";
import cha from "../../assets/img/challenges.png";
import act from "../../assets/img/activities.png";
import { useCompany } from "context/CompanyContext";
import { css } from "@emotion/react";
import { useMe } from "context/MeContext";
import { useList, useOne } from "@pankod/refine-core";
import RouterProvider from "@pankod/refine-react-router-v6";
const { Link } = RouterProvider;

const UserDashboard: React.FC = () => {
  const {brand, path:company_path} = useCompany();
  const {me: user, company} = useMe();
  const {data,refetch} = useOne({
    resource: "users",
    id: user?.id!,
    queryOptions: {
      enabled: Boolean(user?.id)
    }
  })
  const {data:dataChallenge} = useList({
    resource: "user-challenge",
    config: {
      filters: [
        {
          field:"userId",
          operator: "eq",
          value: user?.id,
        }
      ]
    }
  })
  const { data: userBestChallenge } = useList({
    resource: "user-best-challenge",
    config: {
      filters: [
        {
          field:"user_id",
          operator: "eq",
          value: user?.id,
        }
      ]
    }
  });
  const { data: dataUserQuestion } = useList({
    resource: "user-question",
    config: {
      filters: [
        {
          field:"userId",
          operator: "eq",
          value: user?.id,
        }
      ]
    }
  });
  const userQuestion = dataUserQuestion?.data
  //console.log("user-question", userQuestion);
  // get Month HRA
  let totalMonths = 0;
  if (userQuestion && userQuestion.length > 0) {
    const lastItem = userQuestion[userQuestion.length - 1];
    const updatedAt = lastItem?.updatedAt;
    if (updatedAt) {
      const lastUpdateDate = new Date(updatedAt);
      const now = new Date();
      const yearsDiff = now.getFullYear() - lastUpdateDate.getFullYear();
      const monthsDiff = now.getMonth() - lastUpdateDate.getMonth();
      totalMonths = yearsDiff * 12 + monthsDiff;
    } 
  } 

  // get Trophy name
  const {data: dataTrophy} = useList({
    resource: "trophy",
  })
  const userTrophy = user?.trophy
  //console.log(userTrophy)
  const Trophy = dataTrophy?.data || [];
  const sortedTrophies = [...Trophy].sort((a,b)=> b.point - a.point)
  let trophyDetails = null;
  if (userTrophy !== undefined) {
    trophyDetails = sortedTrophies.find((trophy) => userTrophy >= trophy.point);
  }
  console.log("trophy Details:", trophyDetails)

  const users = data?.data
  let img;
  if (company_path==="sd51") {
    img = d51UD;
  } else if (company_path==="grand-junction-housing-authority") {
    img = gjhaUD;
  } else if (company_path==="marshall") {
    img = mmUD;
  } else if (company_path==="monument-health") {
    img = mhUD;
  }
  else if (company_path==="htop") {
    img = htUD;
  }
  return (
    <div className="user-dashboard-page pageload" css={css`
      position: relative;
      .topic {       
        background-color: ${brand?.background_color}70;
        font-size: 23px; 
        font-weight: 600;
        color: #333;
      }
      .topic1 {
        background-color: ${brand?.background_color}15;
        font-size: 16px;
      }

      button {
        margin-top: 17px;
        border: 1px solid ${brand?.background_color};
        @media (max-width:578px) {
          margin-top: 0 !important;
        }
      }
      .challenge-group {
        border: 1px solid ${brand?.background_color};
        border-radius: 10px;
      }
      .challenge-name {
        font-size: 50px;
        color: #d5403e;
        font-weight: 500;
      }
      .main-title-group {
        background-color: ${brand?.background_color}70 !important;
      }
      h1 {
        color: ${brand?.background_color};
      }
    `}>
      <div className="container">
        <div className="main-title-group j-between">
          <div className="main-title">
            {trophyDetails?.name ? <>
              <p>You are a</p>
              <h1>{trophyDetails?.name}</h1></> 
              : <></>}

          </div>
          <img src={img} />
        </div>
        <br/>
        <div className="top j-between">
          <div className="left">
            <Card className="topic title">ACTIVITIES</Card>
            <br/>
            <Card>
              <p>Total Lifetime Points:</p>
              {users?.activityPoint 
              ? <div className="h2">{users?.activityPoint}</div> 
              : <><span style={{fontSize: 15, fontWeight:500}}><br/>Start an activity to earn points!</span></>}
            </Card>
            <br/>
            <Card className="topic1">Small steps, big wins—wellness is yours to claim! Keep up the good work!</Card>
            <Link to={`/${company_path}/activity`}>
              <Button>Continue your journey <FontAwesomeIcon icon={faArrowRightLong} className="arrow-icon"/></Button>
            </Link>
            
          </div>
          <div className="right">
            <Card className="topic title">CHALLENGES</Card>
            <br/>
            <div className="j-between">
              <Card style={{width:"48.5%"}}>
                <p>Total Challenges Joined:</p>
                {dataChallenge?.data?.length!==0 
              ? <div className="h2">{dataChallenge?.data?.length}</div> 
              : <><span style={{fontSize: 15, fontWeight:500}}>No challenges yet. <br/>Join one today to unlock your potential!</span></>}
              </Card>
              <Card style={{width:"48.5%"}}>
                <p>Total Lifetime Points:</p>
                {users?.challenge_point 
                ? <div className="h2">{users?.challenge_point}</div> 
                : <><span style={{fontSize: 15, fontWeight:500}}><br/>Join a challenge to earn points!</span></>}
              </Card>
            </div>
            <br/>
            <Card className="topic1">Challenge yourself and inspire others.<br/>We’re in this together!</Card>
            <Link to={`/${company_path}/challenge`}>
              <Button>Embrace the challenge <FontAwesomeIcon icon={faArrowRightLong} className="arrow-icon"/></Button>
            </Link>
          </div>
        </div>
        <br/>
        <div className="bottom">
          <div className="challenge-group">
            <div className="j-between bottom-wrapper">
              <span className="top-challenge">Your top challenge this year:</span>
              <span className="challenge-name">{
                userBestChallenge?.data[0]?.challenge 
                ? userBestChallenge?.data[0]?.challenge 
                : <div style={{fontSize: 25, color: '#d5403e', fontWeight: 400, padding: '0 20px'}}>Join a challenge and discover your strength!</div>
              }
              </span>
              {/* <span className="challenge-name">Walktober</span> */}
              <Card className="bot-challenge">
                <p>Total Points Earned</p>
                <div className="h2">{userBestChallenge?.data[0]?.points ? userBestChallenge?.data[0]?.points : <span style={{fontSize:14, color: 'rgba(4, 6, 10, 0.5)', fontWeight:400}}>No points earned yet</span>}</div>
                {/* <div className="h2">XXX</div> */}
              </Card>
            </div>
          </div>
          <br/>
          <div className="j-between">
            <div className="j-between bottom-left">
              <img src={umbrella} className="umbrella"/>
            </div>
            <div className="bottom-right">
              <Card className="card">It’s been {totalMonths} months since you last completed an HRA. We recommend that you complete this once every year.</Card>
              <Link to={`/${company_path}/hraresults`}>
                <Button>See where your wellness stands today <FontAwesomeIcon icon={faArrowRightLong} className="arrow-icon"/></Button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UserDashboard;