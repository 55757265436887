import { Form, Input, Tooltip } from "@pankod/refine-antd";
import { ListStats } from "./ListStats";
import type { FormInstance } from "antd";
import { CopyOutlined } from "@ant-design/icons";

export const DiffListStats: React.FC<{ form?: FormInstance<{}> }> = ({ form }) => {
  const onCopy = (currentNumber: number) => {
    const currValue = form?.getFieldValue(["diff_stats"]);
    const needCopyValue = currValue[currentNumber - 1];
    form?.setFieldValue(["diff_stats", currentNumber], needCopyValue);
  };
  return (
    <Form.List name={"diff_stats"}>
      {(fields) => (
        <div>
          {fields.map((field) => {
            return (
              <div key={field.key} className="relative border-solid border-2 border-gray-200 p-3 rounded mt-3">
                <Form.Item name={[field.name, "week"]} hidden>
                  <Input />
                </Form.Item>
                <div className="flex justify-between">
                  <span className="font-bold">Week {field.name + 1}</span>
                  <Tooltip title="aaa"></Tooltip>
                  {field.name > 0 && (
                    <Tooltip title="Duplicate previous week's challenge">
                      <CopyOutlined
                        className="text-[20px] cursor-pointer"
                        onClick={() => {
                          // console.log({ field, index });
                          onCopy(field.name);
                        }}
                      />
                    </Tooltip>

                  )}
                </div>
                <ListStats field={field} form={form} />
              </div>
            );
          })}
        </div>
      )}
    </Form.List>
  );
};
