import { Form, Input, Select } from "@pankod/refine-antd";
import { dataTypeOps } from "../create";
import { CloseCircleFilled, PlusOutlined } from "@ant-design/icons";
import { ListResult } from "./ListResult";
import { FormListFieldData, Switch, Tooltip } from "antd";
import type { FormInstance } from "antd";
export const ListStats: React.FC<{ field?: FormListFieldData; form?: FormInstance<{}> }> = ({ field, form }) => {
  const _name = field ? [field.name, "stats"] : ["stats"];

  return (
    <Form.List name={_name}>
      {(fields, { add, remove }, { errors }) => (
        <div>
          {fields.map((field, index) => {
            return (
              <div key={field.key} className="relative border-solid border-2 border-gray-200 p-3 rounded mt-3">
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: "Title is required!",
                    },
                  ]}
                  label={`Title`}
                  name={[field.name, "title"]}
                  className="w-full pr-[30px]"
                >
                  <Input className="font-normal" />
                </Form.Item>
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: "Description is required!",
                    },
                  ]}
                  label={`Description`}
                  name={[field.name, "description"]}
                  className="w-full pr-[30px]"
                >
                  <Input.TextArea className="font-normal" />
                </Form.Item>
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: "Description is required!",
                    },
                  ]}
                  label={`Data Type`}
                  name={[field.name, "data_type"]}
                  className="w-full pr-[30px]"
                >
                  <Select options={dataTypeOps} className="font-normal" />
                </Form.Item>
                <Form.Item hidden name={[field.name, "order"]} className="w-full pr-[30px]">
                  <Input className="font-normal" />
                </Form.Item>
                <Form.Item
                  name={[field.name, "hidden_required_proof"]}
                  label="Disable Proof Submission"
                  className="w-full pr-[30px]"
                >
                  <Switch className="font-normal" />
                </Form.Item>
                <h4>Results</h4>
                <ListResult field={field} diff_name={_name} />

                {/* <CopyOutlined className="absolute right-[40px] top-[12px] text-[20px] cursor-pointer"/> */}
                <CloseCircleFilled
                  className="absolute right-[12px] top-[12px] text-red-400 text-[20px] cursor-pointer"
                  onClick={() => {
                    remove(field.name);
                  }}
                />

                {/* <Checkbox>Require Proof Submission</Checkbox> */}
              </div>
            );
          })}
          <p className="flex justify-end h-[22px] text-red-700">{errors}</p>

          {fields.length < 30 && (
            <div onClick={() => add()} className="cursor-pointer flex justify-end">
              <PlusOutlined className="mr-2" />
              Add data element
            </div>
          )}
        </div>
      )}
    </Form.List>
  );
};
