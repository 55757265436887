import { DeleteButton, EditButton, List, Table, useTable } from "@pankod/refine-antd";
import { useCompany } from "context/CompanyContext";
import React from "react";
export const TopicList: React.FC = () => {
  const { id } = useCompany();

  const { tableProps } = useTable({
    resource: "topic",
    initialSorter: [
      {
        field: "order",
        order: "asc",
      },
    ],
    permanentFilter: [
      {
        field: "category.company_id",
        operator: "eq",
        value: id,
      },
    ],
    metaData: {
      join: {
        field: "category",
      },
    },
  });

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: true,
    },
    {
      title: "Category",
      dataIndex: ["category", "name"],
      key: "category",
      sorter: true,
    },
    {
      title: "Order",
      dataIndex: "order",
      key: "order",
      sorter: true,
    },

    {
      title: "Actions",
      render: (_: any, record: any) => {
        return (
          <div className="flex space-x-2">
            <EditButton shape="circle" hideText recordItemId={record.id} resourceNameOrRouteName="admin/chat/topic" />
            <DeleteButton shape="circle" hideText recordItemId={record.id} resourceNameOrRouteName="topic" />
          </div>
        );
      },
    },
  ];

  return (
    <div>
      <h1 style={{fontSize: 35, marginTop:20, color:"rgb(4, 6, 10, 0.8)"}}>Chat</h1>
    <List title="Topics" canCreate>
      <Table {...tableProps} rowKey="id" columns={columns} />
    </List>
    </div>
  );
};
