import { DeleteButton, EditButton, List, Table, useTable } from "@pankod/refine-antd";
import { useCompany } from "context/CompanyContext";
import React from "react";

export const MessageList: React.FC = () => {
  const { id } = useCompany();

  const { tableProps } = useTable({
    resource: "message",
    initialSorter: [
      {
        field: "createdAt",
        order: "desc",
      },
    ],
    permanentFilter: [
      {
        field: "user.companyId",
        operator: "eq",
        value: id,
      },
    ],
    metaData: {
      join: [
        {
          field: "post",
        },
        {
          field: "user",
        },
      ],
    },
  });

  const columns = [
    {
      title: "Content",
      dataIndex: "content",
      key: "content",
      sorter: true,
    },
    {
      title: "Post",
      dataIndex: ["post", "name"],
      key: "post.name",
      sorter: true,
    },
    {
      title: "Post by",
      dataIndex: ["user", "email"],
      key: "user.email",
      sorter: true,
    },
    {
      title: "Actions",
      render: (_: any, record: any) => {
        return (
          <div className="flex space-x-2">
            <EditButton
              shape="circle"
              hideText
              recordItemId={record.id}
              resourceNameOrRouteName="admin/chat/message"
            />
            <DeleteButton shape="circle" hideText recordItemId={record.id} resourceNameOrRouteName="message" />
          </div>
        );
      },
    },
  ];

  return (
    <div>
      <h1 style={{fontSize: 35, marginTop:20, color:"rgb(4, 6, 10, 0.8)"}}>Chat</h1>
    <List title="Messages" canCreate>
      <Table {...tableProps} rowKey="id" columns={columns} />
    </List>
    </div>
  );
};
