import { useForm, Form, Input, Select, useSelect, Edit } from "@pankod/refine-antd";
import { useNavigation } from "@pankod/refine-core";
import { useParams } from "@pankod/refine-react-router-v6";
import React from "react";
import MDEditor from "@uiw/react-md-editor";
import removeMarkdown from "markdown-to-text";
import ReactQuill from "react-quill";
import toolbarOptions from "components/TextEditor/toolbarOptions";
export const PostEdit: React.FC = () => {
  const { push } = useNavigation();
  const { id } = useParams();
  const { formProps, saveButtonProps } = useForm({
    resource: "post",
    redirect: false,
    id,
    onMutationSuccess: () => {
      push("/admin/chat/post");
    },
    metaData: {
      join: {
        field: "topic",
      },
    },
  });
  const { selectProps } = useSelect({
    resource: "topic",
    optionLabel: "name",
    optionValue: "id",
  });

  return (
    <Edit saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Form.Item
          className="w-100"
          label={"Name"}
          name="name"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item className="w-100" label={"Content"} name="content">
          {/* <MDEditor
            data-color-mode="light"
            onChange={(e: string | undefined) => {
              formProps.form?.setFieldValue("content_raw", removeMarkdown(e || ""));
            }}
          /> */}
           <ReactQuill theme="snow" modules={{ toolbar: toolbarOptions }} onChange={(e: string | undefined) => {
              formProps.form?.setFieldValue("content_raw", removeMarkdown(e || ""));
            }}/>
        </Form.Item>
        <Form.Item className="w-100" hidden name="content_raw">
          <Input />
        </Form.Item>
        <Form.Item
          label={"Topic"}
          name={["topic", "id"]}
          rules={[
            {
              required: true,
            },
          ]}
          className="w-full"
        >
          <Select {...selectProps} placeholder="Topic" />
        </Form.Item>
      </Form>
    </Edit>
  );
};
