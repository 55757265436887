import { DeleteButton, EditButton, List, Table, useTable } from "@pankod/refine-antd";
import { css } from "@emotion/react";
import React from "react";

export const ResourcesAdmin: React.FC = () => {
  const { tableProps } = useTable({
    resource: "resource",
    metaData: {
      join: ["dimension"],
    },
  });
  console.log({tableProps})

  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
      width: "10%",
    },
    {
      title: "Dimension",
      dataIndex: ["dimension", "name"],
      key: "dimension.name",
      width: "70%",
    },
    {
      title: "Actions",
      render: (_: any, record: any) => {
        return (
          <div
            className="flex space-x-2"
            css={css`
              .eye-icon {
                background-color: white !important;
                border-radius: 50%;
                border: 1px solid #1f1f1f;
                width: 32px;
                height: 32px;
              }
            `}
          >
            <EditButton shape="circle" hideText recordItemId={record.id} />
            <DeleteButton shape="circle" hideText recordItemId={record.id} resourceNameOrRouteName="resource" />
          </div>
        );
      },
      width: "15%",
    },
  ];
  return (
    <List title={<h1 style={{fontSize: 35, marginTop:20, color:"rgb(4, 6, 10, 0.8)"}}>Resources</h1>} canCreate>
      <Table {...tableProps} rowKey="id" columns={columns} />
    </List>
  );
};